import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from "@mui/icons-material/Menu";
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import DashboardIcon from '@mui/icons-material/Dashboard';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import ReportIcon from '@mui/icons-material/Report';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import HomeIcon from '@mui/icons-material/Home';
import PeopleIcon from '@mui/icons-material/People';
import Tooltip from '@mui/material/Tooltip';

export default function Menu() {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem button component="a" href="#">
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>

        <ListItem button component="a" href="#risks">
          <ListItemIcon>
            <DynamicFeedIcon />
          </ListItemIcon>
          <ListItemText primary="Risico-indicatoren" />
        </ListItem>

        <ListItem button component="a" href="#app">
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="App" />
        </ListItem>

        <ListItem button component="a" href="#cases">
          <ListItemIcon>
            <TravelExploreIcon />
          </ListItemIcon>
          <ListItemText primary="Praktijkvoorbeelden" />
        </ListItem>

        <ListItem button component="a" href="#clients">
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText primary="Wegbeheerders" />
        </ListItem>

      </List>
      <Divider />
      <List>

        <ListItem button component="a" href="#about">
          <ListItemIcon>
            <HelpCenterIcon />
          </ListItemIcon>
          <ListItemText primary="Over" />
        </ListItem>

        <ListItem button component="a" href="#contact">
          <ListItemIcon>
            <MailIcon />
          </ListItemIcon>
          <ListItemText primary="Contact" />
        </ListItem>

        <ListItem button component="a" href="#disclaimer">
          <ListItemIcon>
            <ReportIcon />
          </ListItemIcon>
          <ListItemText primary="Disclaimer" />
        </ListItem>

      </List>
      <Divider />
      <List>

        <Tooltip placement="left" title="Open de dashboard applicatie (gemeenten/provincie Noord-Brabant, Gelderland, Rotterdam, Friesland, Groningen, Delft, Overijssel: gebruik knop 'login nieuwe app')">
          <ListItem button component="a" href="https://verkeersveiligheid.dokdata.nl" target="_blank">
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Login" />
          </ListItem>
        </Tooltip>

        <Tooltip placement="left" title="Login nieuwe applicatie voor: gemeenten/provincie Noord-Brabant, Gelderland, Rotterdam, Friesland, Groningen, Delft, Overijssel">
          <ListItem button component="a" href="https://roadsafety.dokdata.nl" target="_blank">
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Login nieuwe app" />
          </ListItem>
        </Tooltip>

      </List>
    </Box>
  );

  return (
    <div>
      {['right'].map((anchor) => ( //, 'left', 'top', 'bottom'
        <React.Fragment key={anchor}>
          <div align="right" style={{ position: 'fixed', top: '0', right: '0', padding: '1rem' }}>
            <IconButton color="inherit" onClick={toggleDrawer(anchor, true)}>
              <MenuIcon />
            </IconButton>
          </div>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}