import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

const Clients = () => {
    return (
        <>
            <Typography variant="h4" color="text.primary" align="center" style={{ paddingTop: '2rem' }}>
                {"Wegbeheerders"}
            </Typography>
            <Grid container justifyContent="center" alignItems="center" spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="body1" color="text.primary" align="center">
                        Doorontwikkeling vanuit een kopgroep van wegbeheerders:
                    </Typography>
                </Grid>
                <div className='filler' />
                {itemData.map((item) => (
                    <Grid item xs={4} sm={2} lg={2} key={item.title}>
                        <div align='center'>
                            <a href={item.link} target="_blank">
                                <img
                                    className='grayscale'
                                    align='center'
                                    src={`${item.img}`}
                                    alt={item.title}
                                    loading="lazy"
                                    height="80px"
                                    width="100px"
                                />
                            </a>
                        </div>
                    </Grid>
                ))}
                <Grid item xs={12}>
                    <div className='filler' />
                    <Typography variant="body1" color="text.primary" align="center">
                        Acht provincies en ongeveer 200 gemeenten:
                    </Typography>
                    <div className='fillerSmall' />
                    <div align='center'>
                        <img
                            className='grayscale rounded'
                            align='center'
                            src={`${'img/wegbeheerders_ongevalrisico.png'}`}
                            alt='Wegbeheerders ongevalrisico.nl'
                            loading="lazy"
                            // height="50vw"
                            width="75%"
                        />
                    </div>
                    <Typography variant="body2" color="text.secondary" align="center">
                        Bron: <Link color="inherit" href='https://www.reddit.com/r/thenetherlands/comments/7xxh3i/beeldmerken_van_de_380_nederlandse_gemeenten/' target="_blank">reddit.com</Link>
                    </Typography>
                </Grid>
            </Grid>
        </>
    )
}

const itemData = [
    {
        img: 'img/logo_gem_rdam.png',
        title: 'Gemeente Rotterdam',
        link: 'https://rotterdam.nl',
    },
    {
        img: 'img/logo_prov_utrecht.png',
        title: 'Provincie Utrecht',
        link: 'https://provincie-utrecht.nl',
    },
    {
        img: 'img/logo_gem_den_haag.png',
        title: 'Gemeente Den Haag',
        link: 'https://denhaag.nl',
    },
    {
        img: 'img/logo_prov_gelderland.png',
        title: 'Provincie Gelderland',
        link: 'https://www.gelderland.nl',
    },
    {
        img: 'img/logo_prov_friesland.png',
        title: 'Provincie Fryslan',
        link: 'https://fryslan.frl',
    },
    {
        img: 'img/logo_prov_drenthe.png',
        title: 'Provincie Drenthe',
        link: 'https://provincie.drenthe.nl',
    },
    {
        img: 'img/logo_prov_limburg.png',
        title: 'Provincie Limburg',
        link: 'https://limburg.nl',
    },
    {
        img: 'img/logo_gem_delft.png',
        title: 'Gemeente Delft',
        link: 'https://delft.nl',
    },
    {
        img: 'img/logo_prov_brabant.png',
        title: 'Provincie Brabant',
        link: 'https://brabant.nl',
    },
    {
        img: 'img/logo_prov_overijssel.png',
        title: 'Provincie Overijssel',
        link: 'https://overijssel.nl',
    },
    {
        img: 'img/logo_prov_groningen.png',
        title: 'Provincie Groningen',
        link: 'https://provinciegroningen.nl',
    },
    {
        img: 'img/logo_gem_eindhoven.png',
        title: 'Gemeente Eindhoven',
        link: 'https://eindhoven.nl',
    },
    // {
    //     img: 'img/logo_havenbedrijf_rotterdam.png',
    //     title: 'Havenbedrijf Rotterdam',
    //     link: 'https://www.portofrotterdam.com/nl',
    // },
];

export default Clients
